@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

input, textarea{
  border-radius: 6px;
  border: 1px solid rgb(229, 232, 235);
}

input::placeholder, textarea::placeholder{
  color: #99a0a7 !important;
}

input:focus, 
input:focus-visible,
textarea:focus, 
textarea:focus-visible{
  outline: none;
  border-width: 0.1px !important;
  box-shadow: rgba(4, 17, 29, 15%) 0px 0px 6px 0px !important;
}

.input-error{
  border-color: rgb(222, 39, 39) !important ;
  box-shadow: rgba(248, 117, 110, 15%) 0px 0px 0px 2pt !important;
}

.input-error:focus,
.input-error:focus-visible{
  border-width: 1px !important;
  border-color: rgb(222, 39, 39) !important ;
  box-shadow: rgba(248, 117, 110, 15%) 0px 0px 0px 2pt !important;
}

.card-shadow{
  box-shadow: 0px 7px 73px #0000001A;
}

.active::after{
  transition: background-color 0.4s ease 0s;
  background-color: rgb(222, 39, 39);
  bottom: 0%;
  content: "";
  display: block;
  height: 4px;
  left: 0px;
  bottom: 0;
  position: absolute;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.custom-shadow{
  transition: all 0.2s ease 0s;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
}

.sidebar-container{
  background-color: rgba(0, 0, 0, 0.25);
}

ul.circled-list li{
  margin-left: 20px;
  margin-bottom: 4px;
}

ul.circled-list li::after{
  content: '';
  position: absolute;
  left: 0;
  width: 10px;
  height: 10px;
  margin-top: 7px;
  border-radius: 100px;
  background: #CBD5E0;
}

.sidebar-open{
  position: fixed;
  top: 70px;
  right: 0px;
  bottom: 0px;
  width: 420px;
  z-index: 90;
  padding: 2px 20px 20px 20px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(229, 232, 235);
  overflow: auto;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
  transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
  visibility: visible;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

@media(max-width: 480px){
  .sidebar-open{
    width: 100%;
  }
}

.sidebar-close{
    position: fixed;
    top: 70px;
    right: 0px;
    bottom: 0px;
    width: 420px;
    z-index: 90;
    padding: 2px 20px 20px 20px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(229, 232, 235);
    overflow: auto;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
    visibility: visible;
    transform: translate3d(100%, 0px, 0px);
    opacity: 0;
}

.wallet-item:hover{
  transition: all 0.2s ease 0s;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
  background-color: rgb(251, 253, 255);
}

.icon-shadow{
  box-shadow: 8.05051px 24.1515px 89.4501px -11.6285px rgba(22, 52, 80, 0.1);
}

.border-16{
  border-width: 16px;
}

.showcase{
  background-repeat: no-repeat;
  background-size: cover;

  background-color: rgb(255, 255, 255);
  background-position: center center;
  opacity: 0.3;
  filter: blur(8px);
  -webkit-mask: linear-gradient(rgb(255, 255, 255), transparent);
}
  
  .showcase-header:hover{
    transition: box-shadow 0.3s ease-in 0s;
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 50px 0px;
  }

  .nft-bg{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('https://picsum.photos/1200/200/?random');
  }

  .options-container{
    top: 1.6rem;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%);
  }

  .options-parent:hover .options-container{
    display: block;
    opacity: 1;
  }

  .createItemContainer input{
    width: 100%;
  }

  .h-fit-screen{
    height: calc(100vh - 240px);
  }

  .upload-modal-container{
    position: relative;
    margin-top: 30px;
    margin-bottom: 40px;
    margin-left: 12px;
    padding: 4px;
    cursor: pointer;
    outline: 3px dashed rgb(204, 204, 204);
    outline-offset: 6px;
    border-radius: 10px;
    width: 280px;
    height: 220px;
    transition: 0.2s ease-in;

    display: grid;
    place-items: center;
  }

  .upload-modal-container:hover{
    background: #ececec;
  }

  .caption-text{
    font-weight: 500;
    font-size: 13px;
    color: rgb(112, 122, 131);
  }

.auth-container{
    background-color: #fbfbfb;
    border: 1px solid #e7e7e7;
    padding: 26px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 5%);
    box-shadow: 0 0 20px rgb(0 0 0 / 5%);
}

.Divider__container{
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e7e7e7;
    line-height: .1em;
}

.Divider__container span {
  background-color: #fbfbfb;
  padding: 0 10px;
}

.SocialButton {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border: 1px solid #dadde0;
  background-color: #fff;
  padding: 11px 17px;
  letter-spacing: .3px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
}

.ReactModal__Overlay{
  z-index: 2000;
  display: grid;
  place-items: center;
  background-color: rgb(27 27 27 / 75%) !important;
}

.gGngoX {
  width: 100%;
  flex-shrink: 0;
}

.gGngoXline{
  background-color: rgb(255, 255, 255);
  border-color: rgb(229, 232, 235);
  border-top-style: solid;
  border-top-width: 1px;
}
.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid rgb(222, 39, 39);
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin-left: 47%;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.plan-container{
  width: 320px;
  height: 50px;
  background: #F9F9F9;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #e7e7e7;
}

.plan-container h4{
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
  z-index: 2;
}

.plan-switch{
  width: 50%;
  height: 42px;
  border-radius: 100px;
  background-color: #049938;
}